import { useState } from "react";
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import WebIcon from '@mui/icons-material/Web';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CloseIcon from '@mui/icons-material/Close';

import "./header.css";

const Header = () => {

  const [showMenu, setShowMenu] = useState(false);


    return(
        <header className="headerAlignment">
              <Link to="/" className="headerImageContainer">
                 <img src={require("../../assets/BNLogoT.png")} alt="Company Logo" />
              </Link>
             
              <div className="navContainer">
                <div className="headerMenu">
                  <nav>
                    <Link to="/">Home</Link>
                    <Link to="/contact">Contact Us</Link>
                    <Link to="/websites">Websites</Link>
                    <Link to="/blog">Blog</Link>
                  </nav>
                </div>
              </div>  
              <div className="headerMenuMobile" onClick={() => setShowMenu(!showMenu)}>
                 <div className="menuIcon"/>
                 <div className="menuIcon"/>
                 <div className="menuIcon"/>
              </div>  
                

                { showMenu ?
                <div className="headerMenuMobileSideBar">
                  <div className="closeIconContainer">
                    <div className="closeIcon" onClick={() => setShowMenu(!showMenu)}>
                      <CloseIcon />
                    </div>  
                  </div>
                  <div to="/" className="headerImageContainer">
                     <img src={require("../../assets/BNLogoT.png")} alt="Company Logo" />
                  </div>
                  <div className="menuLinksMobile">
                    <nav>
                      <Link to="/" className="mobileLink">
                         <HomeIcon style={{marginRight: 10}}/>
                          Home   
                      </Link>
                      <Link to="/contact" className="mobileLink">
                        <EmailIcon style={{marginRight: 10}}/>
                        Contact Us
                      </Link>
                      <Link to="/websites" className="mobileLink">
                        <WebIcon style={{marginRight: 10}}/>
                        Websites
                      </Link>
                      <Link to="/blog" className="mobileLink">
                        <NewspaperIcon style={{marginRight: 10}}/>
                        Blog
                      </Link>
                    </nav>
                  </div>
                </div> : null}
            </header>
    )
  
};

export default Header;