import { useState } from "react";
import { collection, addDoc} from "firebase/firestore";

import { db } from '../../lib/firebase';

import Header from "../../shared/Header/header";

import "./contact.css";




const Contact = () => {

    
    const [messageToSend, setMessageToSend] = useState({
        name: "",
        email: "",
        message: "",
        read: false,
        created: Date.now(),
    });

    const [success, setSuccess] = useState({color: '', message: ''});

    const handleChange = (e, type) => {

        setSuccess({color: '', message: ''});
       
        setMessageToSend({
          ...messageToSend,
          [type]: e.target.value,
        });
      };

      const submit = () => {
          
        if(!messageToSend.name || !messageToSend.email || !messageToSend.message){
            setSuccess({color: 'red', message: "Please fill out all fields"});
            return;
        }

        const ref = collection(db, "messages");


        addDoc(ref, messageToSend)
        .then(() => {
            setSuccess({color: 'green', message: "Message Sent!"});
            setMessageToSend({
                name: "",
                email: "",
                message: "",
                read: false,
                created: Date.now(),
            });
        })
        .catch((error) => {
            setSuccess({message: "Message Failed to Send, Please Try Again", color: 'red'});
            console.error("Error writing document: ", error);
        });
      }


    return(
        <div className="Container">
          <Header />
          <div className="mainContactContainer">
             <div className="contactCard">
                <div className="inputMainContainer">
                   <div className="inputSpacer">  
                     <div className="inputIntro">
                       <h2>Let's Talk</h2>
                       <p>Have a question or project in mind? Reach out to us by sharing your email and the details in the message field below. We're here to assist you!</p>
                     </div>
                     <div className="inputsContainer">
                       <div className="inputContainer">
                         <p>Your Name</p>
                         <input type="text" className="inputField" value={messageToSend.name} onChange={(evt) => {handleChange(evt, 'name')}}/>
                       </div>
                       <div className="inputContainer">
                          <p>Your Email</p>
                          <input type="text" className="inputField" value={messageToSend.email} onChange={(evt) => {handleChange(evt, 'email')}}/>
                       </div>
                       <div className="inputContainer">
                          <p>Your Message</p>
                          <textarea className="inputField" value={messageToSend.message} onChange={(evt) => {handleChange(evt, 'message')}}/>
                       </div>   
                     </div>   
                     <button className="orange-button" onClick={() => submit()}>Send Message</button>
                     {success.message === '' ? null : <p style={{color: success.color, marginTop: 10, textAlign: 'left'}}>{success.message}</p>}     
                   </div>         
                </div>  
                <div className="imageContainer">
                    <img src={require("../../assets/email-illustration.png")} alt="email illustration" width={'90%'}/>
                </div>
             </div>
          </div>
        </div>
    )
  }

export default Contact;