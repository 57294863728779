import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';

import Home from './components/Home/home';
import Blogs from './components/Blogs/blogs';
import BlogPost from './components/Blogs/blogPost';
import Contact from './components/ContactForm/contact';
import Websites from './components/Websites/websites';

const App = () => {

  useEffect(() => {
    
  }, [])



  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/blog" element={<Blogs />} />
          <Route exact path="/blog/:slug" element={<BlogPost />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/websites" element={<Websites />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
