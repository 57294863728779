import { useState, useEffect } from "react";

import { collection, query, where, getDocs } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import { app, db } from '../../lib/firebase';

import Header from "../../shared/Header/header"

import "./blogs.css";

const BlogPost = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [blogData, setBlogData] = useState({});

    useEffect(() => {

        
        if(location.state){
            setBlogData(location.state);
            return
        }

        fetchPost();

    }, [])

    const fetchPost = async () => {

        try{

        const ref = collection(db, "blog-posts");
        const q = query(ref, where("path", "==", location.pathname.split('/')[2]));

        const postSnapshot = await getDocs(q);

        if(postSnapshot.empty){
            navigate('/blog');
        }

        postSnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            setBlogData(doc.data());
           
        });

        }catch(error){
            console.log(error);
            navigate('/blog');
        }
    }

    const renderSections = (type, value, i) => {

        switch(type){
            case 'paragraph':
                return (
                    <div key={`section-${i}`} className="blogDescription">
                        <p>{value}</p>
                    </div>
                )
            case 'image':
                return (
                    <div key={`section-${i}`}>
                        <img src={value} alt="Blog Section Image" width={'70%'}/>
                    </div>
                )
            case 'sub-title':
                return (
                    <div key={`section-${i}`}>
                        <h2 style={{fontSize: 28}}>{value}</h2>
                    </div>
                )
            case 'section-title':
                return (
                    <div key={`section-${i}`}>
                        <h3 style={{fontSize: 20}}>{value}</h3>
                    </div>
                )
            default:
                return null;
        }
    }

    console.log(blogData);
    
     return (
      <div>
         <Header />
        <div className="mainBlogsContainer">
            <div className="mainBlogsContentContainer">
              <div className="blogPostMainContainer">
                     <div className="blogPostContainer" style={{borderBottomWidth: 0}}>
                            <h1 style={{fontSize: 36}}>{blogData.title}</h1>
                        <div className="blogInfoContainer">
                          <div><p><strong>{moment(blogData.updated).format('MMMM Do, YYYY')}</strong></p></div>
                        </div>
                        {blogData.featuredImage ? (
                            <div>
                                 <img src={blogData.featuredImage} alt="Blog Featured Image" width={'90%'}/>
                            </div>
                          
                        ) : null}
                        <div>
                            {!blogData.sections ? null : blogData.sections.map((section, index) => {
                                     return (
                                        <div>
                                             {renderSections(section.type, section.value, index)} 
                                        </div>
                                        )
                             })}
                        </div>
                     </div>
                
              </div>
                
            </div>
        </div>
      </div>
     )
}

export default BlogPost;