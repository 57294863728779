import { useState, useEffect} from "react";
import { collection, getDocs } from "firebase/firestore";
import {useNavigate } from "react-router-dom";
import moment from "moment";

import { db } from '../../lib/firebase';

import Header from "../../shared/Header/header"

import "./blogs.css";

const Blogs = () => {

    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

     fetchBlogs();
    }, [])

    const fetchBlogs = async () => {
         
        const ref = collection(db, "blog-posts");

        try{
            const data = await getDocs(ref);
            setBlogs(data.docs.map(doc => doc.data()));
        }
        catch(error){
            console.log(error);
        }
    }

    return (
      <div>
        <Header />
        <div className="mainBlogsContainer">
            <div className="mainBlogsContentContainer">
              <div className="blogPostMainContainer">
                {blogs.map((blog, index) => { 
                    if(blog.count || blog.live === false){
                        return null;
                    }
                return(
                     <div className="blogPostContainer" key={`${blog.path}-${index}`} style={index === blogs.length - 1 ? {borderWidth: 0} : {}}>
                        <a onClick={() => navigate(`/blog/${blog.path}`, { state: blog })}>
                            <h1>{blog.title}</h1>
                        </a>
                        <div className="blogInfoContainer">
                          <div><p><strong>{moment(blog.updated).format('MMMM Do, YYYY')}</strong></p></div>
                        </div>
                        {blog.featuredImage ? (
                            <a onClick={() => navigate(`/blog/${blog.path}`, { state: blog })}>
                                 <img src={blog.featuredImage} alt="Blog Featured" width={'90%'}/>
                            </a>
                          
                        ) : null}
                        <div className="blogDescription">{blog.description}...<a onClick={() => navigate(`/blog/${blog.path}`, { state: blog })}>Continue reading </a></div>
                     </div>
                   ) }
                )}
              </div>
            </div>
        </div>
      </div> 
    );
}

export default Blogs;