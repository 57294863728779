import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

import { db } from '../../lib/firebase';

import Header from "../../shared/Header/header";
import "./websites.css";





const Websites = () => {

  const [websites, setWebsites] = useState([]);

  useEffect(() => {

    fetchBlogs();
   }, [])

   const fetchBlogs = async () => {

       const ref = doc(db, "websites","featuredWebsites");

       try{

           const data = await getDoc(ref);

           console.log(data);

           if(data.exists()){
                setWebsites(data.data().featuredWebsites);
           }
        
           //setWebsites(data.docs.map(doc => doc.data()));
       }
       catch(error){
           console.log(error);
       }
   }

    return(
        <div>
          <Header />
          <div className='websitesMainContainer'>
            <h2>Our Portfolio</h2>
            <p>Explore a showcase of our diverse and innovative web projects. From sleek designs to powerful functionalities, discover the excellence we bring to every website we've crafted. Your vision, our expertise – see the results for yourself."</p>
            <div className='websiteContainer'>
              {websites.map((site) => {

                return(
                  <div className='websiteDivider'key={`website-${site.id}`}>
                    <div className='websiteImgConatainer'>
                      <img src={`${site.featuredImage}`} alt="Website Screenshot"/>
                    </div>
                    <div className='websiteInfoContainer'> 
                      <h3>{site.title}</h3>
                      <p>{site.description}</p>
                      <Link to={site.websiteUrl} target='_blank'>Check it out here</Link>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
    )
}

export default Websites;