import { useState } from "react";
import { Link } from "react-router-dom";
import { collection, addDoc} from "firebase/firestore";
import { Link as ScrollLink,} from 'react-scroll';

import CreateIcon from '@mui/icons-material/Create';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

import HardwareIcon from '@mui/icons-material/Hardware';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CodeIcon from '@mui/icons-material/Code';
import StorefrontIcon from '@mui/icons-material/Storefront';

import ArticleIcon from '@mui/icons-material/Article';
import SearchIcon from '@mui/icons-material/Search';
import GoogleIcon from '@mui/icons-material/Google';
import AdsClickIcon from '@mui/icons-material/AdsClick';

import AssignmentIcon from '@mui/icons-material/Assignment';
import AddIcon from '@mui/icons-material/Add';
import BuildIcon from '@mui/icons-material/Build';
import InsertChartIcon from '@mui/icons-material/InsertChart';

import { db } from '../../lib/firebase';

import Header from "../../shared/Header/header"


import "./home.css";


const Home = () => {
  const [messageToSend, setMessageToSend] = useState({
    name: "",
    email: "",
    message: "",
    read: false,
    created: Date.now(),
});

const [success, setSuccess] = useState({color: '', message: ''});

const handleChange = (e, type) => {

    setSuccess({color: '', message: ''});
   
    setMessageToSend({
      ...messageToSend,
      [type]: e.target.value,
    });
  };

  const submit = () => {
      
    if(!messageToSend.name || !messageToSend.email || !messageToSend.message){
        setSuccess({color: 'red', message: "Please fill out all fields"});
        return;
    }

    const ref = collection(db, "messages");

    console.log(messageToSend)

    addDoc(ref, messageToSend)
    .then(() => {
        setSuccess({color: 'green', message: "Message Sent!"});
        setMessageToSend({
            name: "",
            email: "",
            message: "",
            read: false,
            created: Date.now(),
        });
    })
    .catch((error) => {
        setSuccess({message: "Message Failed to Send, Please Try Again", color: 'red'});
        console.error("Error writing document: ", error);
    });
  }

   

    return (
      <div>
        <Header />
          <div className="mainHomeContainer">
            <div className="heroSection">
              <div className="leftHeroSection">
                <div className="leftMiniSection">
                  <div className="leftMiniSectionHeader">
                  <h1>Small Business Website</h1>               
                  <h2>Empower your small business with our 4-step
                     website development program, simplifying the 
                     journey from concept to a compelling online 
                     presence
                  </h2>
                  </div>
                  <div className="leftButtons">
                    <button className="orange-button" style={{padding:20}}><Link to='/contact'>Contact Us</Link></button>
                    <button className="green-button">
                      <ScrollLink
                        to="learn-more"
                        spy={true}
                        smooth={true}
                        offset={-70} // Adjust the offset as needed
                        duration={500}
                      >
                        Learn More
                      </ScrollLink>
                    </button>
                  </div>
                </div>
                <div className="circleContainer">
                  <div className="circleDecor1"/>
                  <div className="circleDecor2"/> 
                  <div className="circleDecor3"/>
                </div>
              </div>
              <div className="rightHeroSection">
                <div className="rightMiniSection">
                  <img src={require("../../assets/heroImage.png")} alt="Small Business Developer Illistration"/>
                </div>

              </div>
            </div>
            <div className="processStepsMainConatiner">
               <h3>What's the process?</h3>
               <div className="processStepsContainer">
                 <div className="processStep">
                    <h4>User-friendly Design</h4>
                    <div className="processIconContainer">
                      <CreateIcon style={{marginRight: 10}}/>
                      <WallpaperIcon style={{marginRight: 10}}/>
                      <AutoFixHighIcon style={{marginRight: 10}}/>
                      <EmojiEmotionsIcon style={{marginRight: 10}}/>
                    </div>
                    <p>Crafting websites with an intuitive and user-friendly design, ensuring seamless navigation for visitors of all levels of digital expertise.</p>
                 </div>
                 <div className="processStep">
                    <h4>Built For Functionality</h4>
                    <div className="processIconContainer">
                      <HardwareIcon style={{marginRight: 10}}/>
                      <StorefrontIcon style={{marginRight: 10}}/>
                      <CodeIcon style={{marginRight: 10}}/>
                      <ShoppingCartIcon style={{marginRight: 10}}/>
                    </div>
                    <p>Developing websites that go beyond aesthetics, prioritizing functionality to deliver a smooth and efficient user experience.</p>
                 </div>
                 <div className="processStep">
                    <h4>Utilize SEO</h4>
                    <div className="processIconContainer">
                      <SearchIcon style={{marginRight: 10}}/>
                      <ArticleIcon style={{marginRight: 10}}/>
                      <GoogleIcon style={{marginRight: 10}}/>
                      <AdsClickIcon style={{marginRight: 10}}/>
                    </div>
                    <p>Optimizing web content for search engines to enhance visibility, making sure your website reaches its intended audience effectively.</p>
                 </div>
                 <div className="processStep">
                    <h4>Improve and Upkeep</h4>
                    <div className="processIconContainer">
                      <AssignmentIcon style={{marginRight: 10}}/>
                      <AddIcon style={{marginRight: 10}}/>
                      <BuildIcon style={{marginRight: 10}}/>
                      <InsertChartIcon style={{marginRight: 10}}/>
                    </div>
                    <p>Dedicated to continuous improvement and regular upkeep, ensuring your website remains relevant, secure, and performs at its best over time.</p>
                 </div>
               </div>
            </div>
            <div id="learn-more" className="learnMoreContainer">
              <h3>Do I really need a website?</h3>
             <div className="video-container">
                 <iframe width="100%" height="100%" src="https://www.youtube.com/embed/CXnOyCHVba0?si=iP3sJMBLzYqgRt4G" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
             </div> 
            </div>
            <div className="heroSection" style={{background: '#fff', paddingBottom: 50}}>
                <div className="inputMainContainer">
                   <div className="inputSpacer">  
                     <div className="inputIntro">
                       <h2>Want a Free Estimate?</h2>
                       <h3>Send us an message</h3>
                       <p>Have a question or project in mind? Reach out to us by sharing your email and the details in the message field below. We're here to assist you!</p>
                     </div>
                     <div className="inputsContainer">
                       <div className="inputContainer">
                         <p>Your Name</p>
                         <input type="text" className="inputField" value={messageToSend.name} onChange={(evt) => {handleChange(evt, 'name')}}/>
                       </div>
                       <div className="inputContainer">
                          <p>Your Email</p>
                          <input type="text" className="inputField" value={messageToSend.email} onChange={(evt) => {handleChange(evt, 'email')}}/>
                       </div>
                       <div className="inputContainer">
                          <p>Your Message</p>
                          <textarea className="inputField" value={messageToSend.message} onChange={(evt) => {handleChange(evt, 'message')}}/>
                       </div>   
                     </div>   
                     <button className="orange-button" onClick={() => submit()}>Send Message</button>
                     {success.message === '' ? null : <p style={{color: success.color, marginTop: 10, textAlign: 'left'}}>{success.message}</p>}     
                   </div>         
                </div>  
             </div>
          </div>
       </div>    
    );
}

export default Home;