import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import  firebase from 'firebase/compat/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/storage';

const config = require('./firebaseConfig.json');

const firebaseConfig = {
  apiKey: "AIzaSyB7Nhi5POMOJQ_2eXxN2R4CfYsgH_yLFvU",
  authDomain: "bramnation-7ed48.firebaseapp.com",
  projectId: "bramnation-7ed48",
  storageBucket: "bramnation-7ed48.appspot.com",
  messagingSenderId: "121714653928",
  appId: "1:121714653928:web:338597f6d503b1816ce75d",
  measurementId: "G-XECYH4FRZ4"
};




const app = initializeApp(firebaseConfig);

const db = getFirestore(app);


//const auth = firebase.auth();
//const firestore = firebase.firestore();
//const storage = firebase.storage()

export {
  //auth,
  db,
  firebase,
  app,
  //firestore,
  //storage,
};
